import create from "zustand";

const useStore = create((set) => ({
  supports: [],
  page: 1,
  total: 0,
  setSupport: (value) => set({ supports: value }),
  setTotal: (value) => set({ total: value }),
  setPage: (value) => set({ page: value }),
}));

export default useStore;
