import Layout from "src/components/layout";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMasterData } from "src/context/masterdata-context.js";
import { useDoctor } from "src/context/doctor-context.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.addEventListener("load", () => resolve(reader.result));
    reader.onerror = () => {
      return reject("error is occured while reading file as base64");
    };
    reader.readAsDataURL(file);
  });
}

function Dashboard() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const { fetchPrename, fetchSpeciality, speciality, prename } =
    useMasterData();
  const { createDoctor } = useDoctor();
  const { onChange: onChangeImage } = register("doctor.image");
  const { onChange: onChangeDate } = register("birth_date");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (!data.birth_date) {
      setError("birth_date", {
        type: "manual",
        message: "Please select birth date",
      });
    } else {
      setLoading(true);
      try {
        await createDoctor(data);
        window.alert("Create doctor successfully !");
        navigate("/");
      } catch (error) {
        const errorData = error.response?.data.errors[0];
        if (error.response?.data.errors[0]) {
          setError("cid", {
            type: "manual",
            message: errorData.param + ": " + errorData.msg,
          });
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrename();
    fetchSpeciality();
  }, []);

  let error =
    errors.login_phone_number ||
    errors.cid ||
    errors.birth_date ||
    errors.doctor?.register_no ||
    errors.code;

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mx={4} mt={3} mb={6}>
          <Breadcrumbs sx={{ mb: 1.5 }}>
            <Link underline="hover" color="inherit" href="/">
              Doctors list
            </Link>
            <Typography color="text.primary">Create New Doctor</Typography>
          </Breadcrumbs>
          <Box fontSize={24} mb={3}>
            Pre-register Doctor
          </Box>
          <Card raised={true} sx={{ py: 4, px: 4 }}>
            <Box fontSize={14} mb={1.5}>
              Doctor Profile
            </Box>
            <Box textAlign="center" border="1px solid #C4C4C4" py={3} mb={3}>
              <Avatar
                sx={{ margin: "auto", mb: 3, width: 120, height: 120 }}
                variant="square"
                src={watch("doctor")?.image}
              />
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={async (event) => {
                    const file = event.target.files[0];
                    if (file.size > 1000000) {
                      // showError("The file size is more than 1MB, please use another image.")
                    } else {
                      const img = await getBase64(file);
                      onChangeImage({
                        target: { name: "doctor.image", value: img },
                      });
                    }
                  }}
                />
                <Button variant="contained" component="span">
                  Upload an image
                </Button>
              </label>
              <Box fontSize={14} mt={1} color="text.secondary">
                .JPG file with max 5MB
              </Box>
            </Box>
            <Box fontSize={14} mb={1.5}>
              Information
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Prename"
                  required
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                  {...register("pre_name")}
                >
                  <option></option>
                  {prename.map((data) => (
                    <option key={data._id} value={data._id}>
                      {data.th}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First name"
                  required
                  fullWidth
                  {...register("first_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last name"
                  required
                  fullWidth
                  {...register("last_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone number"
                  required
                  fullWidth
                  {...register("login_phone_number", {
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Invalid Phone Number format.",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="CID"
                  required
                  fullWidth
                  {...register("cid", {
                    minLength: {
                      value: 13,
                      message: "Please fill 13 digits cid",
                    },
                    maxLength: {
                      value: 13,
                      message: "Please fill 13 digits cid",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MobileDatePicker
                  label="Date of birth *"
                  inputFormat="DD/MM/yyyy"
                  value={watch("birth_date") || null}
                  onChange={(event) => {
                    const date = moment(event).format("yyyy/MM/DD");
                    onChangeDate({
                      target: { name: "birth_date", value: date },
                    });
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Register Number"
                  required
                  fullWidth
                  {...register("doctor.register_no", {
                    minLength: {
                      value: 5,
                      message: "Please fill 5 digits register number",
                    },
                    maxLength: {
                      value: 5,
                      message: "Please fill 5 digits register number",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Speciality"
                  required
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                  {...register("doctor.speciality")}
                >
                  <option></option>
                  {speciality.map((data) => (
                    <option key={data._id} value={data._id}>
                      {data.th}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  label="Fee"
                  required
                  fullWidth
                  {...register("doctor.base_fee", {
                    min: {
                      value: 1,
                      message: "Please fill number base_fee",
                    },
                    valueAsNumber: true,
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Description"
                  fullWidth
                  {...register("doctor.description")}
                />
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {error.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Card>
          <Box textAlign="center">
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              sx={{ mt: 4, width: 286 }}
            >
              + Create
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Layout>
  );
}

export default Dashboard;
