import { useForm } from "react-hook-form";
import { useMasterData } from "src/context/masterdata-context.js";
import { useNavigate } from "react-router-dom";
import { useSupport } from "src/context/support-context.js";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Layout from "src/components/layout";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.addEventListener("load", () => resolve(reader.result));
    reader.onerror = () => {
      return reject("error is occured while reading file as base64");
    };
    reader.readAsDataURL(file);
  });
}

function Dashboard() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const { fetchPrename, prename } = useMasterData();
  const { createSupport } = useSupport();
  const { onChange: onChangeImage } = register("image");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await createSupport(data);
      window.alert("Create support successfully !");
      navigate("/support");
    } catch (error) {
      const errorData = error.response?.data.errors[0];
      if (error.response?.data.errors[0]) {
        setError("cid", {
          type: "manual",
          message: errorData.param + ": " + errorData.msg,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPrename();
  }, [fetchPrename]);

  let error = errors.login_phone_number || errors.cid;

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mx={4} mt={3} mb={6}>
          <Breadcrumbs sx={{ mb: 1.5 }}>
            <Link underline="hover" color="inherit" href="/support">
              Support list
            </Link>
            <Typography color="text.primary">Create New Support</Typography>
          </Breadcrumbs>
          <Box fontSize={24} mb={3}>
            Pre-register Support
          </Box>
          <Card raised={true} sx={{ py: 4, px: 4 }}>
            <Box fontSize={14} mb={1.5}>
              Support Profile
            </Box>
            <Box
              sx={{ border: 1, borderColor: "grey.500", borderRadius: 8 }}
              textAlign="center"
              py={3}
              mb={3}
            >
              <Avatar
                sx={{ margin: "auto", mb: 3, width: 120, height: 120 }}
                variant="square"
                src={watch("image")}
              />
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={async (event) => {
                    const file = event.target.files[0];
                    if (file.size > 1000000) {
                      // showError("The file size is more than 1MB, please use another image.")
                    } else {
                      const img = await getBase64(file);
                      onChangeImage({
                        target: { name: "image", value: img },
                      });
                    }
                  }}
                />
                <Button variant="contained" component="span">
                  Upload an image
                </Button>
              </label>
              <Box fontSize={14} mt={1} color="text.secondary">
                .JPG file with max 5MB
              </Box>
            </Box>
            <Box fontSize={14} mb={1.5}>
              Information
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Prename"
                  required
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                  {...register("pre_name")}
                >
                  <option></option>
                  {prename.map((data) => (
                    <option key={data._id} value={data._id}>
                      {data.th}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First name"
                  required
                  fullWidth
                  {...register("first_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last name"
                  required
                  fullWidth
                  {...register("last_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone number"
                  required
                  fullWidth
                  {...register("login_phone_number", {
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Invalid Phone Number format.",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="CID"
                  required
                  fullWidth
                  {...register("cid", {
                    minLength: {
                      value: 13,
                      message: "Please fill 13 digits cid",
                    },
                    maxLength: {
                      value: 13,
                      message: "Please fill 13 digits cid",
                    },
                  })}
                />
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {error.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Card>
          <Box textAlign="center">
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              sx={{ mt: 4, width: 286 }}
            >
              + Create
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Layout>
  );
}

export default Dashboard;
