import axios from "src/Axios";
import React, { createContext, useContext, useMemo, useCallback } from "react";
import useMasterDataStore from "src/store/masterdata";

const MasterDataContext = createContext();
MasterDataContext.displayName = "MasterDataContext";

function MasterDataProvider(props) {
  const prename = useMasterDataStore((state) => state.prename);
  const speciality = useMasterDataStore((state) => state.speciality);
  const setPrenameState = useMasterDataStore((state) => state.setPrename);
  const setSpecialityState = useMasterDataStore((state) => state.setSpeciality);

  const fetchPrename = useCallback(async () => {
    const response = await axios({
      method: "get",
      url: `api/prename/master`,
    });
    setPrenameState(response.data.pre_name);
  }, [setPrenameState]);

  const fetchSpeciality = useCallback(async () => {
    const response = await axios({
      method: "get",
      url: `api/specialized-physician/master`,
    });
    setSpecialityState(response.data.specialized_physician);
  }, [setSpecialityState]);

  const value = useMemo(
    () => ({ fetchPrename, fetchSpeciality, prename, speciality }),
    [fetchPrename, prename, fetchSpeciality, speciality]
  );

  return <MasterDataContext.Provider value={value} {...props} />;
}

function useMasterData() {
  const context = useContext(MasterDataContext);

  return context;
}

export { MasterDataProvider, useMasterData };
