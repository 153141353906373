import create from "zustand";

const useStore = create((set) => ({
  doctors: [],
  page: 1,
  total: 0,
  setDoctor: (value) => set({ doctors: value }),
  setTotal: (value) => set({ total: value }),
  setPage: (value) => set({ page: value }),
}));

export default useStore;
