import * as React from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function BasicModal({ children, name, active, onConfirm }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
      handleToggle();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleToggle })}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleToggle}
        onBackdropClick={loading ? () => {} : () => handleToggle()}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirm to {active ? "Disable" : "Enable"} "{name}" this account!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle}>back</Button>
          <LoadingButton loading={loading} onClick={handleConfirm} autoFocus>
            confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
