import create from "zustand";

const useStore = create((set) => ({
  prename: [],
  speciality: [],
  setPrename: (value) => set({ prename: value }),
  setSpeciality: (value) => set({ speciality: value }),
}));

export default useStore;
