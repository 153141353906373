import axios from "src/Axios";
import React, { createContext, useContext, useMemo, useCallback } from "react";
import useSupportStore from "src/store/support.js";

const SupportContext = createContext();
SupportContext.displayName = "SupportContext";

function SupportProvider(props) {
  const supports = useSupportStore((state) => state.supports);
  const setSupport = useSupportStore((state) => state.setSupport);
  const page = useSupportStore((state) => state.page);
  const total = useSupportStore((state) => state.total);
  const setTotal = useSupportStore((state) => state.setTotal);
  const setPage = useSupportStore((state) => state.setPage);

  const fetchSupports = useCallback(
    async (params) => {
      const response = await axios({
        method: "GET",
        url: `api/support`,
        params,
      });
      let res_support = [...response.data?.supports];
      setSupport(res_support);
      setTotal(response.data?.total_results);
      setPage(response.data?.page);
    },
    [setSupport, setTotal, setPage]
  );

  const createSupport = useCallback(async (data) => {
    try {
      await axios({
        method: "POST",
        url: `api/support`,
        data,
      });
    } catch (error) {
      throw error;
    }
  }, []);

  const activateSupport = useCallback(async (_id, active) => {
    try {
      await axios({
        method: "put",
        url: `api/support/${_id}/actived`,
        data: { active },
      });
    } catch (error) {
      throw error;
    }
  }, []);

  const value = useMemo(
    () => ({
      fetchSupports,
      supports,
      createSupport,
      page,
      total,
      activateSupport,
    }),
    [fetchSupports, createSupport, supports, page, total, activateSupport]
  );

  return <SupportContext.Provider value={value} {...props} />;
}

function useSupport() {
  const context = useContext(SupportContext);
  return context;
}

export { SupportProvider, useSupport };
