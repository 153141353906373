import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSupport } from "src/context/support-context.js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DisableModal from "src/components/doctor/disableModal";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Layout from "src/components/layout";
import LoadingButton from "@mui/lab/LoadingButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import StatusTag from "src/components/doctor/statusTag";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function Dashboard() {
  let navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const { fetchSupports, supports, page, total, activateSupport } =
    useSupport();
  const [searched, setSearched] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSupports();
  }, [fetchSupports]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      setSearched(data);
      await fetchSupports({ ...data, page: 1 });
    } catch (error) {}
    setLoading(false);
  };

  const onPageChange = async (e, _page) => {
    setLoading(true);
    try {
      await fetchSupports({ ...searched, page: _page });
    } catch (error) {}
    setLoading(false);
  };

  const toCreate = () => {
    navigate("/support/create");
  };

  const onSetActive = (_id, active) => async () => {
    try {
      await activateSupport(_id, !active);
      onPageChange(null, page);
    } catch (error) {}
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box my={3} mx={4}>
          <Box fontSize={14} mb={1.5}>
            Supports list
          </Box>
          <Box fontSize={24} mb={1.5}>
            Supports
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <OutlinedInput
                placeholder="Search by Name"
                fullWidth
                {...register("first_name")}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <OutlinedInput
                placeholder="Search by Phone Number"
                fullWidth
                {...register("phone_number")}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={3} md={1}>
              <LoadingButton
                loading={loading}
                variant="outlined"
                type="submit"
                sx={{ height: "100%" }}
              >
                search
              </LoadingButton>
            </Grid>
            <Grid item xs={9} md={5}>
              <Button
                variant="contained"
                sx={{ height: "100%", float: "right" }}
                onClick={toCreate}
              >
                + Create New
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Phone Number</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supports.map((support) => (
                  <TableRow
                    key={support._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {support.first_name + " " + support.last_name}
                    </TableCell>
                    <TableCell align="right">
                      {support.login_phone_number}
                    </TableCell>
                    <TableCell align="right">
                      <StatusTag data={support.status} />
                    </TableCell>
                    <TableCell align="center">
                      <DisableModal
                        active={support.active}
                        name={support.first_name + " " + support.last_name}
                        onConfirm={onSetActive(support._id, support.active)}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                        >
                          {support.active ? "disable" : "enable"}
                        </Button>
                      </DisableModal>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container>
            <Pagination
              component="div"
              count={Math.ceil(total / 10)}
              page={page}
              onChange={onPageChange}
              sx={{ mt: 2, ml: "auto" }}
            />
          </Grid>
        </Box>
      </form>
    </Layout>
  );
}

export default Dashboard;

// {doctors.map((doctor) => (
//   <TableRow
//     key={doctor._id}
//     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//   >
//     <TableCell component="th" scope="row">
//       {doctor.first_name + " " + doctor.last_name}
//     </TableCell>
//     <TableCell align="right">
//       {doctor.login_phone_number}
//     </TableCell>
//     <TableCell align="right">
//       <StatusTag data={doctor.doctor?.status} />
//     </TableCell>
//     <TableCell align="center">
//       <DisableModal
//         active={doctor.active}
//         name={doctor.first_name + " " + doctor.last_name}
//         onConfirm={onSetActive(doctor._id, doctor.active)}
//       >
//         <Button
//           variant="outlined"
//           color="secondary"
//           size="small"
//         >
//           {doctor.active ? "disable" : "enable"}
//         </Button>
//       </DisableModal>
//     </TableCell>
//   </TableRow>
// ))}
