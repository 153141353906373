import { requestOTP, setUpNewPassword, checkPhoneNumber } from "src/api/login";
import { useAuth } from "src/context/auth-context.js";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import LoginContent from "src/components/login/contents/login";
import React, { useState } from "react";
import ResetPasswordWithOTPContent from "src/components/login/contents/resetPasswordWithOTP";

import Logo from "src/assets/images/logo.png";

export default function Login() {
  let navigate = useNavigate();
  let location = useLocation();
  let state = location.state;
  let from = state ? state.from.pathname : "/";
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const { login } = useAuth();
  // state of login ['checkphone', 'setPassword', 'login']
  const [loginStatus, setLoginStatus] = useState("checkphone");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setLoading(true);
    if (loginStatus === "checkphone") {
      try {
        await checkPhoneNumber(data.login_phone_number, {
          pendingCallback: () => setLoginStatus("setPassword"),
          waitingCallback: () => window.alert("Please wait 30 seconds"),
          readyCallback: () => setLoginStatus("login"),
        });
      } catch (error) {
        setError("login_phone_number", {
          type: "manual",
          message: "Cannot find this User",
        });
      }
    } else if (loginStatus === "login") {
      try {
        await login(data);
        navigate(from, { replace: true });
      } catch (error) {
        setError("login_phone_number", {
          type: "manual",
          message: "User or Password is invalid",
        });
      }
    } else if (loginStatus === "setPassword") {
      try {
        if (data.password !== data.confirm_password) {
          throw new Error("mismatch");
        } else {
          await setUpNewPassword(data);
          setLoginStatus("login");
          window.alert("Set password success, please login.");
        }
      } catch (error) {
        if (error.message === "mismatch") {
          setError("password", {
            type: "manual",
            message: "Password and Confirm Password are not the same.",
          });
        } else if (
          error.message === "Invalid request otp" ||
          error.message === "Invalid code"
        ) {
          setError("password", {
            type: "manual",
            message: "The OTP entered is incorrect.",
          });
        }
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container maxWidth="md">
        <Box textAlign="center" mt={8}>
          <Box
            component="img"
            src={Logo}
            sx={{ maxWidth: 442, width: "100%", mb: 5 }}
          />
          <Card
            raised={true}
            sx={{
              maxWidth: 480,
              margin: "auto",
              pt: 4,
              pb: 6,
              px: { xs: 4, sm: 7 },
            }}
          >
            {(loginStatus === "checkphone" || loginStatus === "login") && (
              <LoginContent
                register={register}
                readyToLogin={loginStatus === "login"}
                loading={loading}
                errors={errors}
              />
            )}
            {loginStatus === "setPassword" && (
              <ResetPasswordWithOTPContent
                register={register}
                login_phone_number={watch("login_phone_number")}
                requestOTP={requestOTP}
                loading={loading}
                errors={errors}
              />
            )}
          </Card>
        </Box>
      </Container>
    </form>
  );
}
