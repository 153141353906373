import React from "react";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

export default function SetPassword({ register, errors, loading }) {
  const error_phone = errors.login_phone_number;
  return (
    <div>
      <Typography fontSize={20} mb={1}>
        Set up your Password
      </Typography>
      <Typography fontSize={14} color="textSecondary" mb={2}>
        Enter your phone number associated with the account.
      </Typography>
      {error_phone && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error_phone.message}
        </Alert>
      )}
      <OutlinedInput
        fullWidth
        {...register("login_phone_number", {
          required: "Phone number is required.",
          pattern: { value: /^[0-9]{10}$/, message: "Invalid format." },
        })}
        placeholder="Phone Number"
        sx={{ mb: 3, maxWidth: 360 }}
      />
      <LoadingButton
        loading={loading}
        color="primary"
        variant="contained"
        type="submit"
      >
        next
      </LoadingButton>
      <Typography fontSize={14} mt={3}>
        Or get back to <Link href="/login">Sign In</Link>
      </Typography>
    </div>
  );
}
