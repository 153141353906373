import React from "react";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";

function StatusTag({ data }) {
  return (
    <Box
      component="span"
      bgcolor={
        (data === "pre-registed" && "#FDF2E4") ||
        (data === "actived" && "#EDF3E8")
      }
      color={
        (data === "pre-registed" && "#EF8C21") ||
        (data === "actived" && "#689F38")
      }
      py={1}
      px={1.5}
      borderRadius={18}
    >
      <CircleIcon sx={{ fontSize: 9 }} />{" "}
      {(data === "actived" && "Activated") ||
        (data === "pre-registed" && "Pre-Registed")}
    </Box>
  );
}

export default StatusTag;
