import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import React from "react";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function SetPasswordWithOTP({
  register,
  login_phone_number,
  requestOTP,
  errors,
  loading,
}) {
  ///////// TIMER ////////////////////
  const [initialTime, setInitialTime] = React.useState(0);
  const [startTimer, setStartTimer] = React.useState(false);

  const handleOnClickRequestOTP = () => {
    setInitialTime(30);
    setStartTimer(true);
    requestOTP(login_phone_number);
  };

  React.useEffect(() => {
    if (initialTime > 0) {
      setTimeout(() => {
        // console.log("startTime, ", initialTime)
        setInitialTime(initialTime - 1);
      }, 1000);
    }

    if (initialTime === 0 && startTimer) {
      // console.log("done")
      setStartTimer(false);
    }
  }, [initialTime, startTimer]);
  ///////////////////////////////////

  const [values, setValues] = React.useState({
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let error = errors.password || errors.confirm_password || errors.code;

  return (
    <div>
      <Typography fontSize={20} mb={2}>
        Set up your Password
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error.message}
        </Alert>
      )}
      <OutlinedInput
        fullWidth
        placeholder="New Password"
        {...register("password", {
          required: "Password is required.",
          minLength: {
            value: 8,
            message:
              "Create password does not meet requirements. At lest 8-32 characters. ",
          },
          maxLength: {
            value: 32,
            message:
              "Create password does not meet requirements. At lest 8-32 characters. ",
          },
        })}
        type={values.showNewPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowNewPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        sx={{ mb: 2 }}
      />
      <OutlinedInput
        fullWidth
        placeholder="Confirm Password"
        {...register("confirm_password", {
          required: "Confirm password is required.",
        })}
        type={values.showConfirmPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowConfirmPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        sx={{ mb: 3 }}
      />
      <Typography fontSize={16}>
        Confirmation to set your Password with OTP.
      </Typography>
      <Typography fontSize={14} color="textSecondary" mb={2}>
        We sent it to the number {login_phone_number}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <OutlinedInput
            fullWidth
            placeholder="6 digit code"
            {...register("code", { required: "OTP code is required." })}
          />
        </Grid>
        <Grid item xs={4}>
          <LoadingButton
            loading={startTimer}
            loadingIndicator={initialTime + " ..."}
            color="primary"
            fullWidth
            variant="outlined"
            sx={{ height: "100%" }}
            onClick={handleOnClickRequestOTP}
          >
            Request OTP
          </LoadingButton>
        </Grid>
      </Grid>
      <LoadingButton
        loading={loading}
        color="primary"
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 3 }}
      >
        SUBMIT
      </LoadingButton>
    </div>
  );
}
