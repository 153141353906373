import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Logo from "src/assets/images/logo.png";
import { useForm } from "react-hook-form";
import { requestOTP, setUpNewPassword, checkPhoneNumber } from "src/api/login";
import { useNavigate } from "react-router-dom";

import ResetPasswordContent from "src/components/login/contents/resetPassword";
import ResetPasswordWithOTPContent from "src/components/login/contents/resetPasswordWithOTP";

export default function ResetPassWord() {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    if (step === 0) {
      try {
        await checkPhoneNumber(data.login_phone_number, {
          pendingCallback: () => setStep(1),
          waitingCallback: () => window.alert("Please wait 30 seconds"),
          readyCallback: () => setStep(1),
        });
      } catch (error) {
        setError("login_phone_number", {
          type: "manual",
          message: "Cannot find this User",
        });
      }
    } else if (step === 1) {
      try {
        if (data.password !== data.confirm_password) {
          throw new Error("mismatch");
        } else {
          await setUpNewPassword(data);
          navigate("/login");
          window.alert("Reset password success, please login again.");
        }
      } catch (error) {
        if (error.message === "mismatch") {
          setError("password", {
            type: "manual",
            message: "Password and Confirm Password are not the same.",
          });
        } else if (
          error.message === "Invalid request otp" ||
          error.message === "Invalid code"
        ) {
          setError("password", {
            type: "manual",
            message: "The OTP entered is incorrect.",
          });
        }
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container maxWidth="md">
        <Box textAlign="center" mt={8}>
          <Box
            component="img"
            src={Logo}
            sx={{ maxWidth: 442, width: "100%", mb: 5 }}
          />
          <Card
            raised={true}
            sx={{
              maxWidth: 480,
              margin: "auto",
              pt: 4,
              pb: 6,
              px: { xs: 4, sm: 7 },
            }}
          >
            {step === 0 && (
              <ResetPasswordContent
                register={register}
                loading={loading}
                errors={errors}
              />
            )}
            {step === 1 && (
              <ResetPasswordWithOTPContent
                register={register}
                login_phone_number={watch("login_phone_number")}
                requestOTP={requestOTP}
                errors={errors}
                loading={loading}
              />
            )}
          </Card>
        </Box>
      </Container>
    </form>
  );
}
