import { alpha } from "@mui/material/styles";
import { drawerWidth } from "src/Theme.js";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "src/context/auth-context.js";
import { useNavigate, useMatch } from "react-router-dom";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FaceIcon from "@mui/icons-material/Face";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useRef } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useLayoutStore from "src/store/layout.js";
import useMediaQuery from "@mui/material/useMediaQuery";

import logo from "src/assets/images/logo.png";

const getDrawerState = (state) => state.drawer;
const setDrawerState = (state) => state.setDrawer;

const MyDrawer = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const changeDrawerState = useLayoutStore(setDrawerState);
  const drawer = useLayoutStore(getDrawerState);
  let navigate = useNavigate();

  const { user } = useAuth();
  const firstName = user?.first_name || "";
  const lastName = user?.last_name || "";
  const fullName = `${firstName} ${lastName}`;

  const drawerRef = useRef(null);
  const getParent = () => drawerRef.current?.parentElement;
  const onClick = (e) => () => navigate(e);

  const isSupportPath = !!useMatch({ path: "/support/*" });

  return (
    <Box component="nav" sx={{ flexShrink: { sm: 0 } }} aria-label="navigation">
      <Drawer
        variant={matches === true ? "temporary" : "permanent"}
        open={drawer}
        onClose={() => {
          changeDrawerState(false);
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          container: getParent(),
        }}
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        ref={drawerRef}
      >
        <Toolbar>
          <Box aria-label="logo" sx={{ lineHeight: 0 }}>
            <Link component={RouterLink} to="/">
              <Box
                component="img"
                src={logo}
                alt="logo"
                sx={{ display: "block", maxWidth: 208, maxHeight: 60 }}
              />
            </Link>
          </Box>
        </Toolbar>
        <Divider />
        <Box sx={{ m: 1.5, p: 0.5 }}>
          <Box
            sx={{
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
              borderRadius: 1,
              mb: 1,
            }}
          >
            <Typography variant="body2" sx={{ color: "primary.main", p: 1 }}>
              Admin
            </Typography>
            <Typography variant="body2" sx={{ p: 1 }}>
              {fullName}
            </Typography>
          </Box>
          <Typography component="span" variant="body1" fontWeight="medium">
            Mangement
          </Typography>
          <List>
            <ListItem
              button
              selected={useMatch({ path: "/" })}
              onClick={onClick("/")}
            >
              <ListItemIcon>
                <AccountBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Doctor" />
            </ListItem>
            <ListItem
              button
              selected={isSupportPath}
              onClick={onClick("/support")}
            >
              <ListItemIcon>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItem>
            {/* <ListItem button>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItem> */}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MyDrawer;
