import axios from "src/Axios";
import React, { createContext, useContext, useMemo, useCallback } from "react";
import useDoctorStore from "src/store/doctor";

const DoctorContext = createContext();
DoctorContext.displayName = "DoctorContext";

function DoctorProvider(props) {
  const doctors = useDoctorStore((state) => state.doctors);
  const setDoctor = useDoctorStore((state) => state.setDoctor);
  const page = useDoctorStore((state) => state.page);
  const total = useDoctorStore((state) => state.total);
  const setTotal = useDoctorStore((state) => state.setTotal);
  const setPage = useDoctorStore((state) => state.setPage);

  const fetchDoctors = useCallback(
    async (params) => {
      const response = await axios({
        method: "GET",
        url: `api/doctor`,
        params,
      });

      let res_doc = [...response.data?.doctors];
      setDoctor(res_doc);
      setTotal(response.data?.total_results);
      setPage(response.data?.page);
    },
    [setDoctor]
  );

  const createDoctor = useCallback(async (data) => {
    try {
      await axios({
        method: "POST",
        url: `api/doctor`,
        data,
      });
    } catch (error) {
      throw error;
    }
  }, []);

  const activateDoctor = useCallback(async (_id, active) => {
    try {
      await axios({
        method: "put",
        url: `api/doctor/${_id}/actived`,
        data: { active },
      });
    } catch (error) {
      throw error;
    }
  }, []);

  const value = useMemo(
    () => ({
      fetchDoctors,
      doctors,
      createDoctor,
      page,
      total,
      activateDoctor,
    }),
    [fetchDoctors, doctors, createDoctor, page, total, activateDoctor]
  );

  return <DoctorContext.Provider value={value} {...props} />;
}

function useDoctor() {
  const context = useContext(DoctorContext);
  return context;
}

export { DoctorProvider, useDoctor };
