import { drawerWidth } from "src/Theme.js";
import Box from "@mui/material/Box";
import Header from "src/components/layout/header";
import React from "react";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
          overflow: "scroll",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

export default Layout;
