import axios from "src/Axios";
import { get } from "lodash";

export const requestOTP = async (phone) => {
  try {
    await axios({
      method: "post",
      url: `api/auth/request-otp`,
      data: { login_phone_number: phone },
    });
  } catch (error) {}
};

export const setUpNewPassword = async (data) => {
  try {
    await axios({
      method: "post",
      url: `api/auth/reset-password`,
      data: data,
    });
  } catch (error) {
    const errorOTP = get(error, "response.data.errors[0].msg");
    throw { message: errorOTP };
  }
};

export const checkPhoneNumber = async (
  phone,
  { pendingCallback, waitingCallback, readyCallback }
) => {
  try {
    const res = await axios({
      method: "post",
      url: `api/auth/check-phone-number`,
      data: { login_phone_number: phone },
    });

    const res_status = res.data.status;

    if (res_status === "pending") {
      pendingCallback();
    } else if (res_status === "wait") {
      waitingCallback();
    } else if (res_status === "ready") {
      readyCallback();
    }
  } catch (error) {
    throw error;
  }
};
