import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";

export default function Demo() {
  return (
    <Fragment>
      <Box sx={{ "& > button": { m: 1 } }}>
        <Button variant="outlined">Primary</Button>
        <Button color="secondary">Secondary</Button>
        <Button color="error">Error</Button>
        <Button color="warning">Warning</Button>
        <Button color="success">Success</Button>
      </Box>
      <Box
        my={2}
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="contained">Primary</Button>
        <Button variant="contained" color="secondary">
          Secondary
        </Button>
        <Button variant="contained" color="error">
          Error
        </Button>
        <Button variant="contained" color="warning">
          Warning
        </Button>
        <Button variant="contained" color="success">
          Success
        </Button>
      </Box>
      <Box
        my={2}
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="outlined" size="small">
          Primary
        </Button>
        <Button variant="outlined" color="secondary" size="small">
          Secondary
        </Button>
        <Button variant="outlined" color="error" size="small">
          Error
        </Button>
        <Button variant="outlined" color="warning" size="small">
          Warning
        </Button>
        <Button variant="outlined" color="success" size="small">
          Success
        </Button>
      </Box>
      <Box
        my={2}
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="outlined">Primary</Button>
        <Button variant="outlined" color="secondary">
          Secondary
        </Button>
        <Button variant="outlined" color="error">
          Error
        </Button>
        <Button variant="outlined" color="warning">
          Warning
        </Button>
        <Button variant="outlined" color="success">
          Success
        </Button>
      </Box>
      <Box
        my={2}
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="outlined" size="large">
          Primary
        </Button>
        <Button variant="outlined" color="secondary" size="large">
          Secondary
        </Button>
        <Button variant="outlined" color="error" size="large">
          Error
        </Button>
        <Button variant="outlined" color="warning" size="large">
          Warning
        </Button>
        <Button variant="outlined" color="success" size="large">
          Success
        </Button>
      </Box>
      <Box my={2}>
        <Typography variant="h1" component="div" gutterBottom>
          h1. Heading
        </Typography>
        <Typography variant="h2" gutterBottom component="div">
          h2. Heading
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          h3. Heading
        </Typography>
        <Typography variant="h4" gutterBottom component="div">
          h4. Heading
        </Typography>
        <Typography variant="h5" gutterBottom component="div">
          h5. Heading
        </Typography>
        <Typography variant="h6" gutterBottom component="div">
          h6. Heading
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div">
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2" gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          button text
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          caption text
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          overline text
        </Typography>
      </Box>
    </Fragment>
  );
}
