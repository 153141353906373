import { createTheme } from "@mui/material/styles";

// COMMENT: size of drawer
export const drawerWidth = 256;

const theme = createTheme({
  palette: {
    primary: {
      light: "#339b9a",
      main: "#008281",
      dark: "#005b5a",
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#4F4B4C",
      main: "#231f20",
      dark: "#181516",
      // contrastText: '#ffcc00',
    },
    error: {
      light: "#ff6f60",
      main: "#e53935",
      dark: "#ab000d",
    },
    info: {
      light: "#4c6296",
      main: "#1b3868",
      dark: "#00133d",
    },
    warning: {
      light: "#ffbd54",
      main: "#ef8c21",
      dark: "#b75e00",
    },
    success: {
      light: "#99d066",
      main: "#689f38",
      dark: "#387002",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
    common: {
      grey: "#000000",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 48,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "large",
      },
    },
  },
});

// COMMENT: theme for debug only
window.theme = theme;

export default theme;
