import { AuthProvider } from "src/context/auth-context.js";
import { DoctorProvider } from "src/context/doctor-context.js";
import { MasterDataProvider } from "src/context/masterdata-context.js";
import { SupportProvider } from "src/context/support-context.js";

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <MasterDataProvider>
        <DoctorProvider>
          <SupportProvider>{children}</SupportProvider>
        </DoctorProvider>
      </MasterDataProvider>
    </AuthProvider>
  );
}

export default AppProviders;
