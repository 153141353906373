import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import { useAuth } from "src/context/auth-context.js"
import CreateDoctors from "src/pages/doctors.create.js"
import CreateSupport from "src/pages/support.create.js"
import Dashboard from "src/pages/dashboard.js"
import Demo from "src/Demo.js"
import Login from "src/pages/login"
import ResetPassword from "src/pages/reset-password"
import SupportHome from "src/pages/support.list.js"

function RequireAuth({ children }) {
  let location = useLocation()
  const { user } = useAuth()
  if (!user?._id) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}

function App() {
  const { user } = useAuth()
  // console.log("🚀 ~ file: App.js ~ line 25 ~ App ~ user", user)

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/doctors/create"
        element={
          <RequireAuth>
            <CreateDoctors />
          </RequireAuth>
        }
      />
      <Route
        path="/support"
        element={
          <RequireAuth>
            <SupportHome />
          </RequireAuth>
        }
      />
      <Route
        path="/support/create"
        element={
          <RequireAuth>
            <CreateSupport />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/protected"
        element={
          <RequireAuth>
            <Demo />
          </RequireAuth>
        }
      />
    </Routes>
  )
}

export default App
